<template>
  <div>
    <div class="flix-form-group">
      <editBtn :onClick="function(){ open = !open }">
        <template v-slot:icon>
          <flixIcon id="remove"></flixIcon>
        </template>
        <template v-slot:text>
          {{ $tc('message.appointments', 1) }}-{{ $t('message.cancellation') }}
        </template>
      </editBtn>
    </div>
    <transition name="flixFadeIn">
      <div class="flix-form-group" v-if="open" :key="open">
        <div class="flix-form-group">
          <label class="flix-html-label">{{ $t('message.cancellation') }} {{ $t('message.reasonWhy') }}</label>
          <textarea v-model="reasonWhy" class="flix-form-control" :placeholder="$t('message.add', {name: $t('message.reasonWhy')})"></textarea>
        </div>
        <transition name="flixFadeIn">
          <saveBtn v-if="saveBtn" :key="saveBtn" :onClick="function(){ sendCancellation() }" :settings="{block: true}">
            <template v-slot:text>
              {{ $t('message.save', {name: $t('message.cancellation')}) }}
            </template>
          </saveBtn>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    saveCalendar: Function
  },
  data () {
    return {
      open: false,
      reasonWhy: this.$t('message.reasonWhyDefault'),
      saveBtn: true,
      admin: false
    }
  },
  watch: {
    reasonWhy (val) { this.saveBtn = false; if (val.trim()) { this.saveBtn = true } }
  },
  methods: {
    notifyEmail () {
      var email = this.admin.email
      var link = this.$flix_url + this.$router.resolve({ name: this.$route.name, params: this.$route.params }).href
      if (this.$getUserVariables().user !== false) {
        email = this.data.email
        link = this.$flix_url + this.$router.resolve({ name: 'dashboard' }).href
      }
      var mailtext = this.$root.$t('email.cancelAppointment')
      link = '<a href="' + link + '">' + link + '</a>'
      var details = ''
      details += this.$tc('message.appointments', 1) + ': ' + this.data.booking_date.split('<i class="flix-glyphicon">&#xe046</i>').join('').split('<i class="flix-glyphicon">&#xe055</i>').join('')
      details += '\n\n'
      Object.keys(this.data.data).forEach(function (key) {
        details += key + ': '
        if (typeof this.data.data[key] === 'string') {
          details += this.data.data[key]
        } else if (typeof this.data.data[key] === 'object') {
          details += JSON.stringify(this.data.data[key])
        }
        details += '\n'
      }.bind(this))
      var send = {
        user: this.$route.params.user,
        to: email,
        title: mailtext.title.split('{id}').join(this.data.booking_id),
        text: mailtext.text
          .split('{id}').join(this.data.booking_id)
          .split('{name}').join(JSON.parse(this.data.atitle).title)
          .split('{reasonWhy}').join(this.reasonWhy)
          .split('{details}').join(details)
          .split('{link}').join(link)
      }
      this.$flix_post({
        url: 'assistent/sharingmail',
        data: send,
        callback: function (ret) { window.console.log(ret) }
      })
    },
    changeStatus (callback) {
      var send = {
        user: this.$route.params.user,
        ID: this.data.ID,
        event_id: this.data.form,
        status: 'cancellation'
      }
      this.$flix_post({
        data: send,
        url: '/booking/change_status',
        callback: function (ret) { this.open = false; this.saveCalendar(); callback() }.bind(this)
      })
    },
    sendCancellation () {
      this.changeStatus(function () {
        this.notifyEmail()
      }.bind(this))
    },
    getAdmin () {
      this.$flix_post({
        url: 'user/get_user',
        data: {
          user: this.$route.params.user
        },
        callback: function (ret) { this.admin = ret.data[1] }.bind(this)
      })
    }
  },
  mounted () {
    this.getAdmin()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
